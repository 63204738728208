export default function statusBackgroundColor(status) {

    if (status === "paid") {
        return 'var(--themeColor)';
    } else if (status === "unpaid") {
        return '#ba00a1';
    } else if (status === "released") {
        return '#5ec93a'
    } else {
        return "#42ebff"
    }
}
