import { Button, Space, Table, Typography, notification } from "antd";
import { useHistory } from "react-router-dom";
import { EditOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import ajaxService from "../../../services/ajax-service";
import moment from "moment";
import { Filter } from "../../../components";

const { Text } = Typography

const FREE_USER = "free-user";
const PAID_USER = "paid-user";

const Users = () => {

    let history = useHistory()

    const [filter, setFilter] = useState('')

    const [type, setType] = useState(FREE_USER)
    const [dataSource, setDataSource] = useState({});

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Username',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        {
            title: 'Created Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => (<Text>{moment(created_at).format('DD-MMM-YYYY')}</Text>)
        },
        {
            title: 'User ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Actions',
            key: 'address',
            render: (text, record) => (
                <Space size='small'>
                    <Button type='primary' size="small" style={{ background: "#C9DBD7", color: '#658664', fontSize: '16px' }}>
                        <EditOutlined />
                    </Button>
                    <Button type='primary' size="small" style={{ background: "#EBDEE1", color: '#FF9595', fontSize: '16px' }} danger onClick={() => openDeleteModal(record.id)}>
                        <DeleteOutlined />
                    </Button>
                </Space>
            )
        },
    ];

    const openDeleteModal = (id) => {
        console.log(id)
    }

    const handleSearch = async ({ name }) => {

        const response = await ajaxService.get(`/users?user_type=${type}&name=${name}`)

        const { success, data } = response;

        if (success) {
            if (data.data.length == 0) {
                setFilter('')
                notification.open({ message: `${name} is not found!`, icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            } else {
                setFilter(name)
            }

            setDataSource(response.data)
        }
    }

    const onClick = (type) => {
        setType(type)
        initialize(type)
    }

    const handleChangePage = async (page) => {
        const response = await ajaxService.get(`/users?user_type=${type}&name=${filter}&page=${page}`)

        if (response.success) {
            setDataSource(response.data)
        }

    }

    const initialize = async (type) => {
        const response = await ajaxService.get(`/users?user_type=${type}&name=${filter}`)

        if (response.success) {
            setDataSource(response.data)
        }
    }

    useEffect(() => {
        initialize(type)
    }, [])

    return (
        <>
            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>

                <Filter handleSearch={handleSearch} placeholder="Search User name" />

                <ul className="user-type" style={{ display: 'flex', justifyContent: "space-between", width: '180px' }}>
                    <li style={type === FREE_USER ? { fontWeight: 'bolder' } : {}}><a style={{ color: 'black' }} onClick={() => onClick(FREE_USER)}>Free</a></li>
                    <li style={type === PAID_USER ? { fontWeight: 'bolder' } : {}}><a style={{ color: 'black' }} onClick={() => onClick(PAID_USER)}>Premium</a></li>
                </ul>

            </Space>

            <Table
                columns={columns}
                dataSource={dataSource.data}
                style={{ background: 'none' }}
                rowKey='id'
                pagination={{
                    onChange: (page, size) => handleChangePage(page),
                    current: dataSource.current_page,
                    pageSize: dataSource.per_page,
                    total: dataSource.total,
                }}
            />
        </>
    )
}
export default Users